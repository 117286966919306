<template>
  <div id="leftnavContent">
    <secondary-navigation :menu-entries="menuEntries"></secondary-navigation>
  </div>
</template>

<script>
  import SecondaryNavigation from '@/components/SecondaryNavigation.vue';

  export default {
    data: function() {
      return {
        menuEntries: [
          {
            title: "Player Safety",
            image: require("@/assets/parents-menu/safety.png"),
            links: [
              {
                title: "Player Safety",
                href: "/parents/player-safety",
              },
              {
                title: "Safety Questions",
                href: "/parents/safety-faq",
              },
              {
                title: "Safety Tips",
                href: "/parents/safety-tips",
              },
              {
                title: "Code of Conduct",
                href: "/parents/code-of-conduct",
              }
            ]
          },
          {
            title: "Where to Buy",
            image: require("@/assets/parents-menu/wheretobuy.png"),
            links: [
              {
                title: "Gamebox",
                href: "/parents/where-to-buy/232706",
              },
              {
                title: "Digital Download",
                href: "/parents/where-to-buy/233002",
              },
              {
                title: "Game cards",
                href: "/parents/where-to-buy/232669",
              },
              {
                title: "Promotional Offers",
                href: "/parents/promotional-offers",
              }
            ]
          },
          {
            title: "Membership",
            image: require("@/assets/parents-menu/subscription.png"),
            links: [
              {
                title: "Membership",
                href: "/parents/subscription"
              },
              {
                title: "Membership Questions",
                href: "/parents/subscription-faq"
              }
            ]
          },
          {
            title: "Accolades",
            image: require("@/assets/parents-menu/accolades.png"),
            href: "/parents/accolades",
          },
        ]
      }
    },
    components: {
      SecondaryNavigation,
    }
  }
</script>
