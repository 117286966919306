<template>
  <div id="siteContainer">
    <div id="mainHeading">
      <div class="header">
        <h1><router-link to="/parents">Parents</router-link></h1>
        <breadcrumbs></breadcrumbs>
      </div>
    </div>

    <router-view id="mainColumn1"></router-view>

    <div id="mainColumn2">
      <div id="leftnavContent">
        <parents-menu></parents-menu>
        <div id="leftnavTouts" style="height: 1005px">
          <div class="column-round transparent">
            <b><i></i></b><i><b></b></i><em></em>
            <div class="column-round-body">
              <div class="leftnav-tout">
                <router-link to="/freetoplay">
                  <img
                    src="@/assets/left-nav-touts/free-to-play.png"
                    title="Available Now"
                  />
                </router-link>
              </div>

              <div class="leftnav-tout">
                <router-link to="/parents">
                  <img
                    src="@/assets/left-nav-touts/lego-universe.png"
                    title="LEGO Universe"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import ParentsMenu from "@/components/ParentsMenu.vue";

export default {
  data: function () {
    return {};
  },
  components: {
    ParentsMenu,
  },
};
</script>

<style lang="scss">
  @import "parents/parents.scss";
</style>